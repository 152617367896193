<template>
  <div
      v-if="$config.public.features.manualMaintenanceWarning"
      class="flex box box--error items-center"
  >
    <svg-icon width="24" height="24" class="fill-current mr-4 text-red" name="error" />
    <div>
      <div class="text-xl">{{ $t("WARNING_MAINTENANCE") }}</div>
      <div
          v-html="$config.public.manualMaintenanceWarningText || $t('WARNING_MAINTENANCE_LONG')"
      />
    </div>
  </div>
  <div
    v-else-if="helpTexts && helpTexts.DisplayMaintenanceMessage"
    class="flex box box--error"
  >
    <svg-icon width="24" height="24" class="fill-current mr-2 text-red" name="error" />
    <div>
      <div class="text-xl">{{ helpTexts.MaintenanceMessage }}</div>
      <div
        v-if="helpTexts.MaintenanceMessageLong"
        v-html="helpTexts.MaintenanceMessageLong"
      />
    </div>
  </div>
</template>
<script>
import {useHelpTextsStore} from "~/stores/helpTexts";

export default {
  setup() {
    const helpTextsStore = useHelpTextsStore()
    return {
      helpTextsStore
    }
  },
	computed: {
		helpTexts() {
			return this.helpTextsStore.helpTexts
		},
	},
}
</script>
