<template>
	<modal
		v-if="show"
		:can-hide="false"
		:show="showDelay"
		@close="close"
	>
    <template #header>
      <div slot="header" class="flex flex--align-center">
        <span>{{ $t('WELCOME') }}</span>
      </div>
    </template>
		<template #default>
			<div v-html="welcomeText"></div>
			<show-privacy-information-button
				class="spacing--top"
				:classes="['btn', 'btn--block']"
				:title="$t('SHOW_PRIVACY_INFORMATION_STATEMENT')"
			></show-privacy-information-button>
			<h2>{{ $t('PLEASE_CHANGE_YOUR_PASSWORD') }}</h2>
			<box-error v-if="errorMsg" :html="$t(errorMsg)"></box-error>
			<div v-if="!isReset">
				<form @submit.stop.prevent="changePassword">
					<label
						class="visuallyhidden"
						:for="`welcome_${_uid}_username`"
					>{{ $t('EMAIL_ADDRESS') }}</label
					>
					<input
						:id="`welcome_${_uid}_username`"
						aria-hidden="true"
						autocomplete="username email"
						class="visuallyhidden"
						name="username"
						readonly="readonly"
						tabindex="-1"
						type="text"
						:value="userStore.userData.email"
					/>
					<div class="spacing--bottom--small">
						<label :for="`welcome_${_uid}_oldpw`">{{
								$t('OLD_PASSWORD')
							}}</label>
						<form-password-input
							:id="`welcome_${_uid}_oldpw`"
							v-model="oldPassword"
							autocomplete="current-password"
							required="required"
							:disabled="isDisabled"
						></form-password-input>
					</div>
					<div class="spacing--top--small">
						<label :for="`welcome_${_uid}_newpw`">{{
								$t('NEW_PASSWORD')
							}}</label>
						<form-password-input
							:id="`welcome_${_uid}_newpw`"
							v-model="newPassword"
							autocomplete="new-password"
							required="required"
							:disabled="isDisabled"
							:strength-indicator="true"
						></form-password-input>
					</div>
					<button
						class="visuallyhidden"
						tabindex="-1"
						type="submit"
						:disabled="isDisabled"
					>
						{{ $t('CHANGE_PASSWORD') }}
					</button>
				</form>
			</div>
			<div v-else>
				<box-success :html="$t('SUCCESS_PASSWORD_RESET')"></box-success>
			</div>
		</template>
		<template #footer>
			<button
				class="btn btn--primary btn--block"
				:disabled="isDisabled || !isValidPassword"
				@click.stop.prevent="changePassword"
			>
				<span v-if="isChangingPw"
				><i class="loader h-space--right"></i
				><span>{{ $t('CHANGING_PASSWORD') }}</span></span
				><span v-else>{{ $t('CHANGE_PASSWORD') }}</span>
			</button>
			<button class="btn--link btn--block" @click.stop.prevent="close">
				{{ $t('CHANGE_PASSWORD_LATER') }}
			</button>
		</template>
	</modal>
</template>
<script>
import BoxError from '@/components/BoxError.vue'
import BoxSuccess from '@/components/BoxSuccess.vue'
import FormPasswordInput from '@/components/form/PasswordInput.vue'
import Modal from '@/components/Modal.vue'
import ShowPrivacyInformationButton from '@/components/buttons/ShowPrivacyInformationButton.vue'
import {useUserStore} from "~/stores/user";
import {useHelpTextsStore} from "~/stores/helpTexts";
import {useLocaleStore} from "~/stores/locale";
import {notify} from "@gm/components";

export default {
	name: 'ModalWelcome',
	components: {
		BoxError,
		BoxSuccess,
		FormPasswordInput,
		Modal,
		ShowPrivacyInformationButton,
	},
	setup() {
		const userStore = useUserStore()
    const helpTextsStore = useHelpTextsStore()
		const localeStore = useLocaleStore()
		return {
      helpTextsStore,
			userStore,
			localeStore,
		}
	},
	data() {
		return {
			errorMsg: '',
			isReset: false,
			newPassword: '',
			oldPassword: '',
			show: false,
			showDelay: false,
		}
	},
	computed: {
		welcomeText() {
			const helpTexts = this.helpTextsStore.helpTexts

			return (
				(helpTexts &&
					helpTexts.hasOwnProperty('FIRST_TIME_LOGIN_TEXT') &&
					helpTexts.FIRST_TIME_LOGIN_TEXT) ||
				''
			)
		},
		isChangingPw() {
			return this.userStore.isChangingPw
		},
		isDisabled() {
			return this.isChangingPw
		},
		isValidPassword() {
			return this.oldPassword && this.newPassword
		},
		showWelcomeWindow() {
			return (
				this.userStore.userData.firstTimeLogIn &&
				this.localeStore.locale
			)
		},
	},
	watch: {
		showWelcomeWindow() {
			if (!this.showWelcomeWindow) {
				this.hide()
			} else {
				this.showModal()
			}
		},
	},
	mounted() {
		if (this.showWelcomeWindow) {
			this.showModal()
		}
	},
	methods: {
		changePassword() {
			if (!this.isValidPassword) {
				return
			}

			if (this.newPassword.length < 6) {
				this.errorMsg = 'ERROR_CHANGE_PASSWORD_TOO_SHORT'
				return
			}

			this.errorMsg = ''
			this.userStore.changePassword({
				oldPassword: this.oldPassword,
				newPassword: this.newPassword,
			})
				.then((response) => {
					if (response.status === 200) {
            notify(this.$t('SUCCESS_PASSWORD_CHANGED').toString(), {
              type: 'success',
              icon: 'check',
            })

						this.newPassword = ''
						this.oldPassword = ''
						this.close()
					}
				})
				.catch((error) => {
					if (error.response.data === 'Old password not valid') {
						this.errorMsg = 'ERROR_CHANGE_PASSWORD_OLD_NOT_VALID'
					} else {
						this.errorMsg = error.response.data
					}
				})
		},
		close() {
			this.userStore.showWelcomeMessage(false)
		},
		hide() {
			this.showDelay = false
			setTimeout(() => {
				this.show = false
			}, 300)
		},
		showModal() {
			this.show = true
			setTimeout(() => {
				this.showDelay = true
			}, 300)
		},
	},
}
</script>
