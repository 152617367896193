<template>
	<div
		id="app"
		:class="{
			'sticky-footer--highlight': !isLoggedIn,
			'sticky-footer--backdrop': !isLoggedIn && !isLoggingIn,
		}"
		class="sticky-footer"
	>
    <!-- @gm/components globals. Responsible for displaying any confirmations issued by other components -->
    <GMConfirmations class="absolute whitespace-pre" style="z-index: 99999;"/>
		<div class="sticky-footer__main">
      <!-- Banner for info notices, maintenance warnings etc -->
      <TopInfoBanner v-if="shouldDisplayTopInfoBanner" :color="topInfoBannerColor" />

      <TopMenu/>

			<!-- @gm/components globals. Responsible for displaying any notifications issued by other components -->
			<GMNotifications
        offset-right="10"
				class="absolute z-40 right-10 top-10"
			/>
			<!-- For Tailwind Jit purge to work we need to hardcode the classes here so they're not stripped away -->
			<div class="hidden top-18 right-18 bottom-18 left-18"></div>


			<Banner :key="key"/>

			<!-- MAINTENANCE NOTIFICATION -->
			<div
				v-if="$config.public.features.manualMaintenanceWarning || (helpTexts && helpTexts.DisplayMaintenanceMessage)"
				class="mt-4 constrained"
			>
				<MaintenanceWarning/>
			</div>

			<!-- MAIN CONTENT -->
			<div
				v-if="isLoggedIn"
				class="flex flex-1 nuxt-page-wrapper"
				style="flex-basis: auto"
			>
				<slot />
			</div>

			<!-- LOGIN -->
			<transition class="flex" mode="out-in" name="transition--slide-up">
				<LoginLoader v-if="isLoggingIn" key="loader"/>
				<Login v-else-if="!isLoggedIn" key="login"/>
			</transition>

			<!-- MODALS -->
			<ModalLanguageSelect/>
			<ModalWelcome/>
			<!-- https://dev.azure.com/GlommenMjosen/Portaler%20og%20SBL-app/_workitems/edit/1594/ -->
			<!-- Two different types of "verify-account" modals -->
			<!-- They both serve the same purpose: user must update their details every once in a while -->
			<!-- VerifyAccountWizard is the "new" flow, with BankID login -->
			<!-- ModalVerifyUserDetails is the old modal, only used for certain users, which has been excluded from bankid-verification -->
			<VerifyAccountWizard v-if="!isFetching && isLoggedIn && userShouldVerifyWithBankID"/>
			<ModalVerifyUserDetails v-else-if="!isFetching && isLoggedIn && !userShouldVerifyWithBankID"/>
			<ModalPrivacyInformation/>

		</div>
		<div
			:class="{
				'sticky-footer__footer--dark': !isLoggedIn && !isLoggingIn,
			}"
			class="sticky-footer__footer"
		>
			<Footer/>
		</div>
	</div>
</template>

<script>
import {GMConfirmations, gmGlobal, GMNotifications, notify} from "@gm/components";
import {mapState} from "pinia";
import Banner from '@/components/banner/Switcher.vue'
import Footer from '@/components/Footer.vue'
import Login from '@/components/Login.vue'
import LoginLoader from '@/components/LoginLoader.vue'
import MaintenanceWarning from '@/components/MaintenanceWarning.vue'
import ModalLanguageSelect from '@/components/modal/LanguageSelect.vue'
import ModalPrivacyInformation from '@/components/modal/PrivacyInformation.vue'
import ModalWelcome from '@/components/modal/Welcome.vue'
import TopMenu from '@/components/TopMenu.vue'
import VerifyAccountWizard from "@/components/modal/VerifyAccount/Wizard";
import {useUserStore} from "~/stores/user";
import {useAccountStore} from "~/stores/account";
import {useAccountsStore} from "~/stores/accounts";
import {useDelegationOfAuthorityStore} from "~/stores/delegationOfAuthority";
import {useHelpTextsStore} from "~/stores/helpTexts";

export default {
	components: {
		VerifyAccountWizard,
		Banner,
		Footer,
		Login,
		LoginLoader,
		MaintenanceWarning,
		ModalLanguageSelect,
		ModalPrivacyInformation,
		ModalWelcome,
		TopMenu,
		GMNotifications,
		GMConfirmations,
	},
	setup() {
    useHead({
      bodyAttrs: {
        class: 'light',
      },
    })
    const { $config } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()
    const route = useRoute()
    const delegationOfAuthorityStore = useDelegationOfAuthorityStore()
    const helpTextsStore = useHelpTextsStore()
		const userStore = useUserStore()

    const topInfoBannerColor = computed(() => {
      const color = runtimeConfig.public.topInfoBannerColor
      if (color?.length && ['yellow', 'green', 'red', 'blue'].includes(color.toLowerCase())) {
        return color.toLowerCase()
      }
    })

    const shouldDisplayTopInfoBanner = computed(() => {
      const isEnabled = $config.public.features.topInfoBanner
      if (isEnabled) {
        const requiredUrlPartsString = $config.public.topInfoBannerRequiredUrlParts
        if (requiredUrlPartsString?.length) {
          const requiredUrlParts = requiredUrlPartsString.toLowerCase().replace(' ', '').split(',')
          return requiredUrlParts.find((part) => route.fullPath.toLowerCase().includes(part))
        }
        return true
      }
      return false
    })

    onMounted(() => {
      helpTextsStore.fetch()
    })

		return {
      delegationOfAuthorityStore,
      helpTextsStore,
			userStore,
      topInfoBannerColor,
      shouldDisplayTopInfoBanner,
		}
	},
	computed: {
		...mapState(useUserStore, ['isCheckingLoginStatus', 'isLoggedIn', 'isLoggingIn']),
		...mapState(useAccountsStore, ['isFetching']),
		helpTexts() {
			return this.helpTextsStore.helpTexts
		},
		key() {
			return this.$route.path.substring(0, 40)
		},
		userShouldVerifyWithBankID() {
      const accountsStore = useAccountsStore()
      return accountsStore.userShouldVerify
		},
	},
	watch: {
		'$route.params.accountId': {
			handler(accountId) {
				if (accountId) {
          const accountStore = useAccountStore()
          accountStore.setActive(accountId).then(() => {
						this.delegationOfAuthorityStore.detectCurrentUserAndAccountScenario(accountId)
					})
				}
			},
			immediate: true,
		},
		isCheckingLoginStatus() {
			// If the login status is being checked, and this is taking more than a few milliseconds
			// the notification will display, it will remain infinitely (duration: -1) untill the login status check
			// state changes to negative, at which point it is re-set
			// Docs: https://github.com/euvl/vue-notification

			setTimeout(() => {
				if (this.isCheckingLoginStatus) {
          notify(this.$t('CHECKING_LOGIN').toString(), {
            type: 'info',
            icon: 'clock-outline',
            loading: true,
            duration: 0,
          })
				} else {
          gmGlobal().notificationsReset()
				}
			}, 500)
		},
		isLoggedIn() {
			this.fetch()
		},
	},
	mounted() {
		this.fetch()
		/*		document.addEventListener("visibilitychange", () => {
  		if( document.visibilityState ) {
  			this.checkLogin()
  		}
		}) */
	},
	methods: {
		checkLogin(force = false) {
			this.userStore.checkLogin(force)
		},
		fetch() {
			if (this.isLoggedIn) {
				const accountsStore = useAccountsStore()
				accountsStore.fetch().then(() => {
					if (this.$route.params.accountId) {
						this.setActiveAccount()
					}
				})
			}
      // this.helpTextsStore.fetch()

			if (this.$route.query.reauth) {
				this.$router.replace({
					query: {
						reauth: undefined,
					},
				})

				this.checkLogin(true)
			}
		},
		setActiveAccount() {
			const accountStore = useAccountStore()
			accountStore.setActive(this.$route.params.accountId)
		},
	},
}
</script>

<style>
/*.nuxt-page-wrapper {
	overflow: hidden;
}
*/
.container {
	width: 100%;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  width: 100vw;
	transition: all 0.3s ease-in-out;
}

/* entering start */
.slide-right-enter-from,
.slide-left-leave-to {
	opacity: 0;
	transform: translate(-100%, 0);
}

.slide-left-enter-from,
.slide-right-leave-to {
	opacity: 0;
	transform: translate(100%, 0);
}
</style>
