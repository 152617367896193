<template>
	<div>
		<GMModal
ref="modal"
						 :show="showModal"
						 :mandatory="!canCloseModal"
						 size="large"
						 class="pb-16"
						 @close="showModal = false"
		>

			<template v-if="currentStep" #header>
				<div class="w-80">{{ currentStep.title }}</div>
			</template>

			<transition-group tag="span" :name="transitionName" class="relative">
				<IncorrectInformation
v-if="showIncorrectInformationInfoBox"
															key="incorrect-information"
															class="max-w-sm"
															:is-forced-to-verify="isForcedToVerify"
															:must-be-verified-by-the-end-of-date-formatted="mustBeVerifiedByTheEndOfDateFormatted"
															@go-back="showIncorrectInformationInfoBox = false"
															@postpone-verification-click="onPostponedVerificationClick"
				/>
				<component
:is="currentStepComponent"
									 v-else-if="currentStepComponent"
									 :key="currentStepName"
									 :is-forced-to-verify="isForcedToVerify"
									 :must-be-verified-by-the-end-of-date-formatted="mustBeVerifiedByTheEndOfDateFormatted"
									 :number-of-days-to-postpone="numberOfDaysToPostpone"
									 :is-first-time-verification="isFirstTimeVerification"
									 class="max-w-sm"
									 @go-next="goNext"
									 @go-back="goBack"
									 @enable-close-modal="canCloseModal = true"
									 @incorrect-information-clicked="showIncorrectInformationInfoBox = true"
									 @close-wizard="verificationCompleted = true"
									 @postpone-verification-click="onPostponedVerificationClick"
				/>
			</transition-group>

			<template #footer>
				<Button v-if="canCloseModal" class="w-full flex justify-center" weight="primary" @click="verificationCompleted = true">
					<svg-icon name="cross" class="w-5 h-5 text-green-400 fill-current"/>
					{{$t('CLOSE')}}
				</Button>
			</template>

		</GMModal>
	</div>
</template>

<script lang="ts">
import {GMModal} from '@gm/components';
import type {TranslateResult} from 'vue-i18n';
import BankId from '~/components/modal/VerifyAccount/BankId.vue';
import ConfirmAccountDetails from '~/components/modal/VerifyAccount/ConfirmAccountDetails.vue';
import Button from '~/components/pages/my-account/Button.vue';
import IncorrectInformation from '~/components/modal/VerifyAccount/IncorrectInformation.vue';
import VerificationComplete from '~/components/modal/VerifyAccount/VerificationComplete.vue';
import {BankIdApi, type BankIdVerificationDto} from '~/gen/openapi/portalService';
import {formatDateI18n} from '~/helpers/format-date';
import {useAccountsStore} from "~/stores/accounts";
import {useBankIDStore} from "~/stores/bankID";
import ConfirmNameAndAddress from '~/components/modal/VerifyAccount/ConfirmNameAndAddress.vue';

const TRANSITION_FORWARD = 'transition--slide-forward'
const TRANSITION_BACK = 'transition--slide-back'

/**
 * Wizard modal for verifying the logged in user, both contact and account.
 * The wizard will in short:
 * - login to bankid
 * - make the user (contact) update their name and address with official information from bankid
 * - - this is the step that will actually "verify" a user.
 * - - the next steps are settings that can be found in the settings page, but is presented here for convenience
 * - present the user with their *account* information (email, phone, bank account, etc), and ask them to change/confirm it
 * - present information about the Right of Use for their account or property, and route them to the correct settings-page where they can sign it
 */
export default defineComponent({
	components: {
		IncorrectInformation,
		BankId,
		ConfirmAccountDetails,
		GMModal,
		Button,
	},
	setup() {
    const bankIDStore = useBankIDStore()
    const accountsStore = useAccountsStore();
		const {t} = useI18n()
		const steps: {
			[key: string]: {
				component: any,
				next: string,
				prev: string,
				title: string | TranslateResult,
			}
		} = {
			'bankid': {
				title: t('verifyUser.LOGIN_PAGE_TITLE'),
				'next': 'confirm-name',
				'prev': '',
				component: BankId,
			},
      'confirm-name': {
        title: t('verifyUser.CONFIRM_PAGE_TITLE'),
        'next': 'confirm',
        'prev': '',
        component: ConfirmNameAndAddress,
      },
			'confirm': {
				title: t('verifyUser.CONFIRM_PAGE_TITLE'),
				'next': 'done',
				'prev': 'confirm-name',
				component: ConfirmAccountDetails,
			},
			'done': {
				title: t('verifyUser.CONFIRM_PAGE_TITLE'),
				'next': '',
				'prev': '',
				component: VerificationComplete,
			}
		}
		const showIncorrectInformationInfoBox = ref(false)
		const currentStepName = ref('bankid')
		const currentStep = computed(() => steps[currentStepName.value])
		const currentStepComponent = computed(() => currentStep.value?.component || null)
		const transitionName = ref(TRANSITION_FORWARD)
		const needsVerification = ref(false)
		const verificationCompleted = ref(false)
		watch(() => bankIDStore.openVerificationModalTrigger, () => {
			showIncorrectInformationInfoBox.value = false
			verificationCompleted.value = false
		})
		const showModal = computed(() => {
			const accounts = accountsStore.items
			return !verificationCompleted.value && (accounts.length > 0 && accounts.some((a: any) => a.IsPrimaryAccount && a.Role === 1) && needsVerification.value)
		})
		const { locale } = useI18n()
		const {$axios, $config} = useNuxtApp()
		const bankIdApi = new BankIdApi(undefined, $config.public.apiBaseHost, $axios)
		const bankIdVerificationDto = ref<BankIdVerificationDto>()
		const isForcedToVerify = computed(() => {
			// be relaxed on the defaults in case api-call goes south
			const {
				BankIdVerificationPostponedDate = (function defaultDate(){
					// 100 years from now
					const d = new Date()
					d.setUTCFullYear(d.getUTCFullYear() + 100, d.getUTCMonth(), d.getUTCDate())
					return d.toISOString()
				})(),
				Verified = true,
				NeedReVerification = false
			} = (bankIdVerificationDto.value || {})

			return (!Verified || NeedReVerification) &&
				new Date(BankIdVerificationPostponedDate).getTime() < new Date().getTime()
		})

		const numberOfDaysToPostpone = computed(() => {
			const {
				BankIdVerificationPostponedDate = (function defaultDate(){
					// 100 years from now
					const d = new Date()
					d.setUTCFullYear(d.getUTCFullYear() + 100, d.getUTCMonth(), d.getUTCDate())
					return d.toISOString()
				})(),
			} = (bankIdVerificationDto.value || {})
			const postponedDate = new Date(BankIdVerificationPostponedDate)
			const now = new Date()
			const diff = postponedDate.getTime() - now.getTime()
			return Math.ceil(diff / (1000 * 3600 * 24))
		})

		const mustBeVerifiedByTheEndOfDateFormatted = computed(() => {
			if (!bankIdVerificationDto.value || !bankIdVerificationDto.value?.BankIdVerificationPostponedDate) {
				const d = new Date()
				d.setDate(d.getDate() + numberOfDaysToPostpone.value)
				return formatDateI18n(d, locale.value, {dateStyle: 'short'})
			}

			return formatDateI18n(new Date(bankIdVerificationDto.value.BankIdVerificationPostponedDate), locale.value, {dateStyle: 'short'})

		})

		onMounted(async () => {
			needsVerification.value = !!(await bankIDStore.checkIfBankIDAuthenticationIsNeeded())
			try {
				const response = await bankIdApi.bankIdCheckBankVerificationPostponeDate()
				bankIdVerificationDto.value = response.data
				needsVerification.value = bankIdVerificationDto.value?.NeedReVerification || needsVerification.value
			} catch (e) {
				console.error(e)
			}
		})

		const isFirstTimeVerification = computed(() => {
			return bankIdVerificationDto.value?.LastVerifiedWithBankId == null
		})

		const goNext = () => {
			if (currentStep.value?.next) {
				transitionName.value = TRANSITION_FORWARD
				currentStepName.value = currentStep.value.next
			}
		}
		const goBack = () => {
			if (currentStep.value?.prev) {
				transitionName.value = TRANSITION_BACK
				currentStepName.value = currentStep.value.prev
			}
		}

		const canGoNext = computed(() => currentStep.value && !!currentStep.value.next)

		const onPostponedVerificationClick = async () => {
      bankIDStore.cancelVerification()
			verificationCompleted.value = true
		}

		const canCloseModal = ref(false)

		return {
			steps,
			currentStep,
			currentStepName,
			currentStepComponent,
			transitionName,
			canGoNext,
			goNext,
			goBack,
			showIncorrectInformationInfoBox,
			showModal,
			onPostponedVerificationClick,
			verificationCompleted,
			isForcedToVerify,
			mustBeVerifiedByTheEndOfDateFormatted,
			canCloseModal,
			numberOfDaysToPostpone,
			isFirstTimeVerification
		}
	}
})
</script>
