<script setup lang="ts">
import Button from '~/components/pages/my-account/Button.vue';
import {GMAlert, GMIcon} from '@gm/components'
import {useBankIDStore} from '~/stores/bankID';
import formatAddress from '~/helpers/format-address';

const emit = defineEmits(['go-next'])

const bankIDStore = useBankIDStore()
const address = computed(
    () => bankIDStore.personInfoFromBankId?.PlaceOfLiving
)
const officialName = computed(() => bankIDStore.personInfoFromBankId?.FullName)
const addressFormatted = computed(() => address.value != null ? formatAddress(address.value) : '')
const bankidErrors = computed(() => bankIDStore.errors)
const router = useRouter()
const syncError = ref('')
const submitInProgress = ref(false)

async function approveNameAndAddressChanges() {
  try {
    syncError.value = ''
    submitInProgress.value = true
    await Promise.all([
      bankIDStore.syncBankIDInformation(),
      bankIDStore.approveUpdatedInformationFromBankID(),
    ])
    emit('go-next')
  } catch (e) {
    console.error('Could not confirm account details', e)
    syncError.value = 'ERROR_GENERIC'
  } finally {
    submitInProgress.value = false
  }
}

async function loginToBankIdAgain() {
  await router.replace({ path: router.currentRoute.value.path, query: {startVerification: '1'} })
  location.href = "/bankid/login"
}

</script>

<template>
  <div>
    <template v-if="bankidErrors && bankidErrors.length">
      <GMAlert type="error">
        <ul>
          <li v-for="err of bankidErrors" :key="err">
            <span v-if="$te(err)" v-html="$t(err)"/>
          </li>
        </ul>
        <Button weight="primary" @click="loginToBankIdAgain">{{ $t('LOG_IN') }}</Button>
      </GMAlert>
    </template>
    <template v-else>

      <div class="grid grid-cols-1 gap-4">
        <p class="my-3 text-lg">{{ $t('verifyUser.IS_INFORMATION_CORRECT') }}</p>
        <div class="bg-gray-300 p-4 relative">
          <h3 class="text-gray-600 mb-2 text-lg font-semibold">{{ $t('verifyUser.ABOUT_YOU') }}</h3>
          <dl>
            <dt class="font-bold text-gray-500 text-sm uppercase">{{ $t('NAME') }}</dt>
            <dd class="mb-4 text-xl">{{ officialName }}</dd>

            <dt class="font-bold text-gray-500 text-sm uppercase">{{ $t('PLACE_OF_LIVING') }}</dt>
            <dd class="mb-4 text-xl">{{ addressFormatted }}</dd>
          </dl>
          <GMAlert type="info" class="mt-4">
            {{ $t('verifyUser.WE_CHECKED_FREG') }}
            <a class="flex items-center mt-2 font-bold" href="https://www.skatteetaten.no/min-side/"
               target="_blank">{{ $t('verifyUser.GO_TO_FREG') }}
              <GMIcon name="arrow-right" class="w-5 h-5 text-blue-600 inline ml-2"/>
            </a>
          </GMAlert>
        </div>
      </div>

      <div class="flex flex-col items-stretch mt-4">
        <Button
            :disabled="submitInProgress"
            class="justify-center col-span-2"
            :icon-before="submitInProgress ? 'in-progress' : 'check'"
            :spin-icon="submitInProgress"
            @click.stop.prevent="approveNameAndAddressChanges"
        >
          {{ $t('CONFIRM_CHANGES_AND_SAVE') }}
        </Button>
      </div>

    </template>
  </div></template>
